import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.mypage');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-mypage',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.mypage/view.scss */
/* src/app/page.mypage/view.scss: no such file or directory */`],
})
export class PageMypageComponent implements OnInit {
    constructor(@Inject( Service)         public service: Service,    ) { }

    async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true);
        await this.info();
        this.data.nickname = this.service.auth.session.nickname || "";
        await this.show("myroom");
        await this.service.render();
    }

    public async info() {
        const { code, data } = await wiz.call("info");
        if (code !== 200) return;
        const { icon } = data;
        this.data.icon = icon;
        this.service.auth.session.icon = icon;
        await this.service.render();
    }

    public tab = "myroom"; // myroom / mgmt
    public async show(tab) {
        await this.service.loading.show();
        this.tab = tab;
        let prefix = {
            myroom: "작업공간",
            mgmt: "이미지",
        }
        this.list = new Array(10).fill(undefined).map((_, i) => ({name: `${prefix[tab]} ${i+1}`}))
        await this.service.loading.hide();
    }
    public tabClass(tab) {
        if (this.tab !== tab)
            return "border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300";
        else
            return "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500";
    }

    public list = [];

    public data = {
        nickname: "",
        icon: null,
    };

    public async onFileSelected() {
        const filenode = this.service.file.createFilenode({ accept: "image/*" });
        filenode.addEventListener("change", (event) => {
            this.service.file.resize(event.target.files[0], 60, 1.0)
                .then(base64String => {
                    this.data.icon = base64String;
                    this.service.auth.session.icon = base64String;
                    this.service.render();
                });
        });
        filenode.click();
    }

    public async update() {
        const body = this.service.copy(this.data);
        const { code } = await wiz.call("update", body);
        if (code !== 200) return await this.service.error("에러 발생!");
        this.service.auth.session.nickname = body.nickname;
        this.service.auth.session.icon = body.icon;
        this.service.success("정보 변경 성공!");
    }
}

export default PageMypageComponent;