import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.login');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-login',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.login/view.scss */
/* src/app/page.login/view.scss: no such file or directory */`],
})
export class PageLoginComponent implements OnInit {

    constructor(@Inject( Service)         public service: Service,    ) { }

    async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(false, "/main");
    }

    public view = "login";
    public show(view) {
        this.data.username = "";
        this.data.password = "";
        this.data.email = "";
        this.data.notice = false;

        this.view = view;
        this.service.render();
    }

    public data = {
        username: "",
        password: "",
        notice: false,
    };
    public async login() {
        if (!this.data.username) return;
        if (!this.data.password) return;

        const body = {
            username: this.data.username,
            password: this.service.auth.hash(this.data.password),
        };
        const { code } = await wiz.call("login", body);
        if (code !== 200) return await this.service.error("아이디 혹은 비밀번호가 일치하지 않습니다.");
        location.href = "/main";
    }

    public async register() {
        if (!this.data.username) return await this.service.error("아이디를 입력해주세요.");
        if (!this.data.password) return await this.service.error("비밀번호를 입력해주세요.");
        if (this.data.password.length < 8) return await this.service.error("8자리 이상의 비밀번호를 설정해주세요.");
        if (this.data.password !== this.data.password_repeat) return await this.service.error("비밀번호가 일치하지 않습니다.");

        const body = this.service.copy(this.data);
        delete body.password_repeat;
        body.password = this.service.auth.hash(this.data.password);

        const { code } = await wiz.call("register", body);
        if (code !== 200) return await this.service.error("중복된 아이디가 존재합니다.");
        await this.service.success("회원가입 완료!");
        this.show("login");
    }
}

export default PageLoginComponent;