import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.main');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-main',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.main/view.scss */
.max-wrap {
  max-width: 1440px !important;
}`],
})
export class PageMainComponent implements OnInit {

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow();
    }

    public tab = 0;
    public menus = ["기획 및 각색", "콘티", "선화", "채색", "효과"];

    public wrapWidth = 1440;
    public imgWidth = 697;
    public indent = (this.wrapWidth - this.imgWidth) / 2;
    public carousel = {
        pos: this.indent,
        prev: () => {
            const tab = Math.max(this.tab - 1, 0);
            this.carousel.move(tab);
        },
        next: () => {
            const tab = Math.min(this.tab + 1, this.menus.length - 1);
            this.carousel.move(tab);
        },
        move: (tab) => {
            this.tab = tab;
            this.carousel.pos = this.indent - (this.tab * this.imgWidth);
            this.service.render();
        }
    };

    public form = {
        name: "",
        org: "",
        hp: "",
        email: "",
        text: "",
    };

    public async send() {
        const body = this.service.copy(this.form);
        console.debug("send form", body)
        // const { code } = await wiz.call("send", body);
        // if (code !== 200) return;
        this.service.success("소중한 의견 감사드립니다.\n\n최대한 빠른 시일내에 답변 드리도록 하겠습니다.");
    }
}

export default PageMainComponent;