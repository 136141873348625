import { Inject } from '@angular/core';
import templateSource from './app.component.html';
              import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz');
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'app-root',
template: templateSource || '',
    styles: [``],
})
export class AppComponent implements OnInit {
    constructor(@Inject( Service)         public service: Service,@Inject( Router)         public router: Router,@Inject( ChangeDetectorRef)         public ref: ChangeDetectorRef,    ) { }

    public async ngOnInit() {
        await this.service.init(this);
        this.service.copy = (obj) => {
            return JSON.parse(JSON.stringify(obj));
        }
        this.service.error = async (message, title = "ERROR") => {
            return await this.service.alert.show({
                title,
                message,
                action: "close",
                cancel: false,
            });
        }
        this.service.success = async (message, title = "SUCCESS") => {
            return await this.service.alert.show({
                title,
                status: "success",
                message,
                action: "close",
                actionBtn: "success",
                cancel: false,
            });
        }
    }
}
