import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav.user');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { HostListener } from '@angular/core';

@Component({
    selector: 'wiz-component-nav-user',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.nav.user/view.scss */
.navbar {
  display: block;
  background: transparent;
  padding: 0;
}
.navbar .section {
  display: flex;
  align-items: center;
}
.navbar .section:last-child {
  border-top: 1px solid var(--wc-border);
}
.navbar .section .container {
  max-width: none !important;
  min-height: 52px;
  align-items: center;
}
.navbar .nav-item {
  position: relative;
  z-index: 1000;
  min-width: 130px;
  text-align: center;
}
.navbar .nav-item .nav-link {
  font-weight: 900;
  font-family: "main-eb";
}
.navbar .nav-item .active {
  color: var(--wc-blue);
}
.navbar .nav-item .sub-menu {
  display: none;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  padding: 6px 12px;
  background-color: rgba(251, 251, 251, 0.95);
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
}
.navbar .nav-item .sub-menu .nav-item {
  padding-left: 0;
  padding-right: 0;
}
.navbar .nav-item:hover .sub-menu {
  display: block;
}
.navbar .nav-item:hover .sub-menu a.nav-item {
  height: 44px;
  font-family: main-eb;
}
.navbar .nav-item:hover .sub-menu a.nav-item:hover {
  color: #1156aa;
}
.navbar .container {
  display: flex;
  justify-content: initial;
}
.navbar .navbar-brand img {
  height: 58px;
}
.navbar .navbar-brand span {
  color: var(--wc-gray);
  font-size: 18px;
}
.navbar .navbar-toggler {
  color: var(--wc-blue);
  margin-right: 12px;
}
@media (min-width: 769px) {
  .navbar .nav-item.active:after {
    bottom: 4px;
  }
}
@media (max-width: 768px) {
  .navbar {
    min-height: 0;
  }
  .navbar .section .container {
    min-height: 0;
  }
  .navbar .navbar-brand span {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .navbar .nav-item {
    min-height: 48px;
  }
  .navbar .nav-item.active:after {
    bottom: 0;
  }
  .navbar .section-sub {
    background: var(--wc-blue);
  }
  .navbar .section-sub .nav-link {
    color: #fff;
  }
  .navbar .section-sub .navbar-collapse.collapse.show {
    height: 100vh;
  }
}
.navbar .user-area {
  align-items: center;
}
.navbar .user-area .nav-item {
  min-width: auto;
}
.navbar .user-area .nav-item .btn-icon {
  background-color: #E4E4E4;
}

.dropdown-menu {
  top: 48px;
  right: 0;
}

.v-center {
  display: flex;
  align-items: center;
}

.user-icon {
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
}

.btn-mypage.btn-primary span {
  color: white;
}
.btn-mypage.btn-primary .user-icon {
  --tblr-text-opacity: 1;
  color: rgba(var(--tblr-primary-rgb), var(--tblr-text-opacity)) !important;
}`],
})
export class ComponentNavUserComponent implements OnInit {
    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.loadIcon();
    }

    @HostListener('document:click')
    public clickout() {
        this.service.navbar.toggle(true);
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }

    public sessionTimeout() {
        const s = +new Date();
        const e = +new Date(this.service.auth.session.session_expires_at);

        function formatMicroseconds(milliseconds) {
            let seconds = Math.floor(milliseconds / 1000);
            milliseconds %= 1000;

            let minutes = Math.floor(seconds / 60);
            seconds %= 60;

            let hours = Math.floor(minutes / 60);
            minutes %= 60;

            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        }

        return formatMicroseconds(e - s);
    }

    public async logout() {
        let res = await this.service.alert.show({
            title: 'Logout',
            message: '정말 로그아웃을 하시겠습니까?',
            cancel: 'Cancel',
            actionBtn: 'error',
            action: 'logout',
            status: 'error'
        });
        if (!res) return;
        location.href = `/auth/logout`;
    }

    public icon = null;
    public async loadIcon() {
        this.icon = null;
        const { code, data } = await wiz.call("icon");
        if (code === 200 && data) this.icon = data;
        await this.service.render();
    }
}

export default ComponentNavUserComponent;