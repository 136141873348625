import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.footer');
import { OnInit } from '@angular/core';

@Component({
    selector: 'wiz-component-footer',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.footer/view.scss */
/* src/app/component.footer/view.scss: no such file or directory */`],
})
export class ComponentFooterComponent implements OnInit {
    public async ngOnInit() {}

    public jh = "jh.yoon@yshub.co.kr";
    public kh = "kh.yook@yshub.co.kr";
}

export default ComponentFooterComponent;